<template>
  <b-card no-body>
    <template v-if="!loading">

      <b-card-header>
        <div>

        <b-card-title>{{$t('dash.cards.schools_by_country')}}</b-card-title>
        <b-card-text class="font-small-2">
            {{$t('dash.cards.top', { num: '5' })}}
          </b-card-text> 
        </div>
      </b-card-header>

      <b-card-body>
        <!-- apex chart -->
        <vue-apex-charts
          type="pie"
          height="325"
          class="mt-2 mb-1"
          :options="customersPie.chartOptions"
          :series="customersPie.series"
        />

        <!-- list group -->
        <div class="pt-25">
          <div
            v-for="(data,index) in chartData.listData"
            :key="index"
            class="d-flex justify-content-between"
            :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
          >
            <div class="series-info">
              <feather-icon
                :icon="data.icon"
                size="16"
                class="mr-50"
                :class="data.iconColor"
              />
              <span class="font-weight-bolder">{{ data.text }}</span>
            </div>
            <span>{{ data.result }}</span>
          </div>
        </div>
      </b-card-body>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import { dashboardSchoolsByCountry } from '@/api/routes'

import {
  BCardText,
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,BSpinner
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BSpinner,
    VueApexCharts,
    BCardText,

  },
  data() {
    return {
      loading: false,
      themeColors: ['primary', 'warning', 'danger', 'secondary', 'success'],
      chartData: {},
      customersPie: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["ddd"],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 1,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger, $themeColors.secondary, $themeColors.success],
        },
      },
    }
  },
  created() {
    this.getSchoolsByCountry()
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async getSchoolsByCountry(){
      this.loading = true

      await this.$http.post(dashboardSchoolsByCountry,{}).then(response => {
        //// console.log(response.data, "getSchoolsByCountry")

        let series = []
        let labels = []
        let listData = []

        response.data.schools_by_country.forEach((element, idx) => {
          series.push(element.total)
          labels.push(element.name)
          listData.push({
            icon: "CircleIcon",
            iconColor: 'text-'+this.themeColors[idx],
            result: element.total,
            text: element.name
          })
        });

        this.customersPie.series = series
        this.customersPie.chartOptions.labels = labels
        //// console.log(this.customersPie.chartOptions.labels, "------")
        this.chartData.listData = listData

        this.loading = false
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    }
  }
}
</script>
