<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <template v-if="!loading && schools_by_partner.length > 0">
      <b-card-header>
          <b-card-title>{{$t('dash.cards.schools_by_partner')}}</b-card-title>
          <!-- <b-dropdown
            :text="'Top '+perPage"
            variant="transparent"
            class="dropdown "
            right
            toggle-class="p-0 mb-25"
            size="sm"
          >
            <b-dropdown-item
              @click="perPage = po"
              v-for="po in page_options"
              :key="po"
            >
              {{ po }}
            </b-dropdown-item>
          </b-dropdown> -->
        
      </b-card-header>
      <b-card-body>
        <div
          v-for="partner in slice_list[page-1]"
          :key="partner.id"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                :src="partner.logo_url"
                variant="light"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ partner.description }}
              </h6>
              <small>{{ partner.total_licenses }} {{$t('dash.cards.total_licences')}}</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder text-success"
          >
            {{ partner.schools }}
          </div>
        </div>
      </b-card-body>
      <b-card-footer>
            <p class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon rounded-circle m-1"
                :disabled="page==1"
                @click="page = page-1"
              >
                <feather-icon icon="ChevronLeftIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon rounded-circle m-1"
                :disabled="last_page"
                @click="page = page+1"
              >
                <feather-icon icon="ChevronRightIcon" />
              </b-button>
            </p>
          </b-card-footer>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import { dashboardSchoolsByPartner } from '@/api/routes'

import {
  BCard,BSpinner, BCardHeader, BCardTitle, BCardBody, BCardText,
  BButton,
  BCardFooter,
  BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BButton,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      schools_by_partner: [],
      loading: false,

      perPage: 5,
      page: 1,
    }
  },
  mounted(){
    this.getActiveSchoolsByPartner()
  },
  computed:{
    last_page(){
      return this.slice_list.length == this.page
    },
    slice_list(){
        let arregloDeArreglos = []; // Aquí almacenamos los nuevos arreglos
        for (let i = 0; i < this.schools_by_partner.length; i += this.perPage) {
          let sliced_list = this.schools_by_partner.slice(i, i + this.perPage);
          arregloDeArreglos.push(sliced_list);
        }
        return arregloDeArreglos
    }
  },
  methods:{
    async getActiveSchoolsByPartner(){
      this.loading = true
      await this.$http.post(dashboardSchoolsByPartner,{}).then(response => {
        // console.log(response.data)
         this.schools_by_partner  = this.sortPartnersBySchoolsAmount(response.data.info)
        this.loading = false
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    sortPartnersBySchoolsAmount(objs){
      let new_arr = objs.sort((a,b) => b.schools - a.schools);
      return new_arr
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>
