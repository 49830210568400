<template>
   <b-card no-body>
    <template v-if="!loading">
      <b-card-header class="flex-column align-items-start">
        <b-card-title>{{$t('Schools')}}</b-card-title>
        <b-card-text class="text-muted mt-25">
          {{$t('dash.cards.go_to')}}
          <b-link
          to="schools">
            {{$t('models.schools')}}
          </b-link> {{$t('dash.cards.to_apply_filters')}}
        </b-card-text>
      </b-card-header>
      <paginated-table
        :data="schools"
        ability="Schools"
        link='school'
        :canDelete="true"
        :canRecover="false"
        :canSelect="false"
        :loading_data="loading"
        @delete="deleteModel"
      ></paginated-table>

    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import { dashboardSchoolsByPartnerId, deleteSchool } from '@/api/routes'

import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import { BCard, BCardHeader, BCardTitle, BCardText, BLink, BSpinner, BPagination } from 'bootstrap-vue'
export default {
  components:{
    PaginatedTable,
    BCard, BCardHeader, BCardTitle, BCardText, BLink, BSpinner, BPagination
  },
  data(){
    return {
      schools: [],
      loading: false
    }
  },
  created(){
    this.getSchools()
  },
  methods:{
    async getSchools(){
      this.loading = true
      await this.$http.post(dashboardSchoolsByPartnerId,{}).then(response => {
        this.schools = response.data.schools
        this.loading = false
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('schools.sure'), {
            title: this.$t('schools.delete'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'school_id': id
              }
              this.$http.post(deleteSchool, data).then( response =>{
                // console.log(response)
                if(response.data.status == 200){
                  this.getSchools();

                  this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

                } else {
                  // console.log(response.data, "NO SE PUEDE ELIMINAR");
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                }

              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
    },
  }
}
</script>

<style>

</style>