<template>
  <section id="dashboard-analytics"  >
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <welcome-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" sm="6" xs="12">
        <b-link :to="{name: 'dreamers'}">
          <statistic-card-vertical
            icon="SmileIcon"
            :statistic-title="$t('Dreamers')"
            color="info"
            statistic=""
          />
        </b-link>
      </b-col>
      <b-col lg="3" sm="6" xs="12">
        <b-link :to="{name: 'groups'}">
          <statistic-card-vertical
            icon="UsersIcon"
            :statistic-title="$t('Groups')"
            color="secondary"
            statistic=""
          />
        </b-link>
      </b-col>
      <b-col lg="3" sm="6" xs="12">
        <b-link :to="{name: 'learning-paths'}">
          <statistic-card-vertical
            icon="CompassIcon"
            :statistic-title="$t('Learning Paths')"
            color="primary"
            statistic=""
          />
        </b-link>
      </b-col>
      <b-col lg="3" sm="6" xs="12">
        <b-link :to="{name: 'reports'}">
          <statistic-card-vertical
            icon="FileTextIcon"
            :statistic-title="$t('Reports')"
            color="success"
            statistic=""
          />
        </b-link>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import { BRow, BCol, BLink } from 'bootstrap-vue'
import WelcomeCard from './cards/WelcomeCard.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
components:{
    BRow, BCol, BLink,

StatisticCardVertical,
    WelcomeCard,
  },
}
</script>

<style>

</style>