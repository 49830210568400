<template>
  <section id="dashboard-analytics"  >
    <b-row class="match-height">
      <b-col
        lg="4"
        md="12"
      >
        <welcome-card />
      </b-col>
      <b-col
        lg="8"
        md="12"
      >
        <statistics />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <schools-by-partner />
      </b-col>
      <!-- <b-col
        lg="4"
        md="6"
      >
        <version-releases
          :showCurrent="true"
          :showLatests="true"
          :showNext="true"
         />
      </b-col> -->
      <b-col
        lg="6"
        md="6"
      >
        <schools-by-country />
      </b-col>
    </b-row>
   <!-- 
    @TODO Alvaro! :) Ver con Jesus 
    <b-row>
      <b-col>
        <ul>
          <li>links a informacion FAQ</li>
          <li>licencias que vencen hoy / mañana / en tres dias</li>
        </ul>
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import WelcomeCard from './cards/WelcomeCard.vue'
import Statistics from './cards/Statistics.vue'
import SchoolsByPartner from './cards/SchoolsByPartner.vue'
/* import VersionReleases from './cards/VersionReleases.vue' */
import SchoolsByCountry from './cards/SchoolsByCountry.vue'

export default {
  components:{
    WelcomeCard,
    Statistics,
    SchoolsByPartner,
    /* VersionReleases, */
    SchoolsByCountry,

    BRow, BCol
  },  
}
</script>

<style>

</style>