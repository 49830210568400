<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <template v-if="!loading">
      <b-card-header>
        <div>
          <b-card-title>{{$t('dash.cards.usage_by_'+context)}}</b-card-title>
          <b-card-text class="font-small-2">
            {{$t('dash.cards.top', { num: '10' })}}
          </b-card-text>
        </div>
        
      </b-card-header>

      <b-card-body v-if="this.recentContentUsage">
        <div
          v-for="item in items"
          :key="item.id"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                :src="context == 'group' ? require('@/assets/images/avatar/groups/'+item.group_avatar+'.png') : require('@/assets/images/avatar/AvatarSprite_'+item.dreamer_avatar+'.png')"
                variant="light"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ context == 'group' ? item.group : item.dreamer }}
              </h6>
              <small>{{$t('dash.cards.total_games')}}: {{ item.total_games }}</small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-success">
            {{  item.total_time }} m.
          </div>
        </div>
      </b-card-body>
      <b-card-body v-else>
        {{$t('dash.cards.no_recent_usage')}}
      </b-card-body>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import { dashboardUsageBySchool } from '@/api/routes'

import {
  BCard, BCardHeader, BSpinner,BCardTitle, BCardBody, BCardText,
  BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,BSpinner,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  data(){
    return {
      loading: false,
      items:[],
      recentContentUsage: false,
    }
  },
  props:{
    context: {
      type: String,
      required: true
    }
  },
  created() {
    this.getData()
  },
  computed:{
    },
  methods:{
    async getData(){
      this.loading = true
      this.recentContentUsage = false
      await this.$http.post(dashboardUsageBySchool,{context:this.context}).then(response => {
        if (response.data.usage.length != 0) {
          this.recentContentUsage = true
        } else {
          this.recentContentUsage = false
        }
        this.items = response.data.usage
        
        this.loading = false
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>
