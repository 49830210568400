<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <template v-if="!loading">

      <b-card-header>
        <div>
          <b-card-title>{{$t('dash.cards.most_used_apps')}}</b-card-title>
          <b-card-text class="font-small-2">
            {{$t('reports.last_week')}}
          </b-card-text>
        </div>
      </b-card-header>

      <!-- body -->
      <b-card-body v-if="this.recentContentUsage">
        <div
          v-for="(browser) in contents"
          :key="browser.browserImg"
          class="browser-states"
          style="margin-top:0; margin-bottom:1.2rem"
        >
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-img
                :src="browser.browserImg"
                alt="browser img"
                height="42"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="align-self-center my-auto">
                {{ browser.name }} 
              </h6>
              <small>{{$t('content_types.'+browser.type)}}</small>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center justify-content-end" style="min-width: 90px;">
            <span class="font-weight-bold text-body-heading mr-1">{{ humanReadableTime(Math.round(browser.usage)) }}</span>
            <!-- <vue-apex-charts
              type="radialBar"
              height="30"
              width="30"
              :options="chartData[index].options"
              :series="chartData[index].series"
            /> -->
          </div>
        </div>
      </b-card-body>
      <b-card-body v-else>
        {{$t('dash.cards.no_recent_usage')}}
      </b-card-body>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import { dashboardContentStats } from '@/api/routes'

import {
  BCard, BCardHeader, BSpinner,BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BSpinner,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      loading: false,

      contents: {},
      chartData: [],
      chartClone: {},
      recentContentUsage: false,
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  props:{
    context: {
      type: String,
      required: false,
      default: ''
    }
  },
  created() {
    this.getContentStats()
  },
  methods:{
    async getContentStats(){
      this.loading = true
      this.recentContentUsage = false
      await this.$http.post(dashboardContentStats,{context:this.context}).then(response => {
        // console.log("-------- CONTENTS STATS ", response.data)
        this.contents = response.data.contents
        if (this.contents.length > 0) {
          for (let i = 0; i < this.contents.length; i += 1) {
            const chartClone = JSON.parse(JSON.stringify(this.chart))
            chartClone.options.colors[0] = this.chartColor[i]
            chartClone.series[0] = response.data.chartSeries[i]
            this.chartData.push(chartClone)
          } 
          this.recentContentUsage = true
          this.loading = false
        } else {
          this.loading = false
          this.recentContentUsage = false
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>
