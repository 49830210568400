<template>
  <section id="dashboard-analytics" >
    <admin-dash v-if="userData.role == 'admin'" />
    <support-dash v-else-if="userData.role == 'support'" />
    <partner-dash v-else-if="userData.role == 'partner'" />
    <school-admin-dash v-else-if="userData.role == 'school-admin'" />
    <school-teacher-dash v-else-if="userData.role == 'teacher'" />
    <content-catalog v-else-if="userData.role == 'licencing'" />
    <user-dash v-else />


  </section>
</template>

<script>
import contentCatalog from '@/views/virtual-catalog/Contents.vue'

import AdminDash from './AdminDash.vue'
import SupportDash from './SupportDash.vue'
import PartnerDash from './PartnerDash.vue'
import SchoolAdminDash from './SchoolAdminDash.vue'
import SchoolTeacherDash from './SchoolTeacherDash.vue'
import UserDash from './UserDash.vue'
export default {
  components:{
    AdminDash,
    PartnerDash,
    SupportDash,
    SchoolAdminDash,
    SchoolTeacherDash,
    UserDash,
    contentCatalog
  },
  data(){
    return {
      userData: this.$store.getters['user/getUserData']
    }
  }
}
</script>

<style>

</style>